@import '~bootstrap/dist/css/bootstrap.min.css';
@import "bootstrap/scss/bootstrap";
$fa-font-path : "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "~@flaticon/flaticon-uicons/css/all/all";
@import './assets/css/vendor/material-design-iconic-font.min.css';
@import './assets/css/vendor/et-line.css';
@import '~animate.css/animate.min.css';
@import '~react-modal-video/scss/modal-video.scss';
@import '~lightgallery.js/dist/css/lightgallery.css';
@import './assets/scss/style.scss';